import React from 'react';
import { Link, graphql, navigate } from 'gatsby';
import { window } from 'browser-monads';
import Layout from '../components/layout'
import Nav from '../components/nav';
import SEO from '../components/seo';
import '../components/home/home.css'
import './archive.css';

import header from '../images/background-2.jpg'


const Archive = (props) => {

    const blogContent = props.data.allContentfulBlog
    const { currentPage, numPages } = props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/blog' : `/blog/${currentPage -1}`
    const nextPage = `/blog/${currentPage + 1}`

return (
  <Layout>
    <SEO
      title="Blog"
      keywords={["ross perkel", "perkel blog", "perkel site"]}
    />
    <Nav />
    <header>
      <div className="archive__section">
        <div
          className="archive__hero"
          style={{ backgroundImage: `url(${header})` }}></div>
        <div className="archive__nav">
          <div className="archive__nav--link">
            <Link to="/blog">Blog Home</Link>
          </div>
        </div>
      </div>
    </header>

    <div className="feed">
      {blogContent.edges.map(edge => (
        <div
          key={edge.node.id}
          className="card"
          style={{
            backgroundImage: `url(${edge.node.featuredImage.fluid.src}`,
          }}
          onClick={() => navigate(`/blog/${edge.node.slug}`)}
        >
           <p className='card__title'>{edge.node.title}</p>
        </div>
      ))}
    </div>

    <div className="pagination">
      <div className="pagination__item">
        {!isFirst && (
          <Link to={prevPage} rel="prev">
            <div className="arrow__back"></div>
          </Link>
        )}
      </div>
      <div className="pagination__item">
        {!isLast && (
          <Link to={nextPage} rel="next">
            <div className="arrow__next"></div>
          </Link>
        )}
      </div>
    </div>
  </Layout>
)
}

export default Archive

export const pageQuery = graphql`
    query ArchiveQuery ($skip: Int!, $limit: Int!) {
        allContentfulBlog(
            sort: { fields: [createdAt], order: DESC }
            filter: {
            node_locale: {eq: "en-US",}
            }
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    createdAt
                    featuredImage {
                        fluid(maxWidth: 1200, quality: 85) {
                            src
                            ...GatsbyContentfulFluid
                    }
                }
            }
        }
    }
}
`